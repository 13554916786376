// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#AgreementPage-module-error {\n  background-color: rgb(255, 214, 173);\n  border-radius: 5px;\n  padding: 15px;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n#AgreementPage-module-error > code {\n  border: 1px solid var(--color-border);\n  padding: 15px;\n  border-radius: 5px;\n  background: rgb(244, 244, 244);\n  display: flex;\n}", "",{"version":3,"sources":["webpack://./src/components/AgreementPage/AgreementPage.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,qCAAqC;EACrC,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,aAAa;AACf","sourcesContent":["#error {\n  background-color: rgb(255, 214, 173);\n  border-radius: 5px;\n  padding: 15px;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n#error > code {\n  border: 1px solid var(--color-border);\n  padding: 15px;\n  border-radius: 5px;\n  background: rgb(244, 244, 244);\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "AgreementPage-module-error"
};
export default ___CSS_LOADER_EXPORT___;
